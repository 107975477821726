<template>
    <div id="app-bar-wrapper">
        <v-app-bar
            app
            color="primary"
        >
            <v-btn
                plain
                icon
                @click="$router.push({ 'name': 'Catalog' })"
                class="pa-0 ml-n2"
                v-if="this.$route.name != 'Catalog'"
            >
                <v-icon>fa-chevron-left</v-icon>
            </v-btn>
            <v-btn
                plain
                icon
                class="pa-0 ml-n2"
                style="visibility: hidden"
                v-else
            ></v-btn>

            <v-spacer />

            <router-link :to="{ name: 'Catalog' }" class="animated-logo">
                <lottie-animation
                    class="ml-md-n2 d-flex"
                    path="animations/clevers-logo-v2.json"
                    :width="($vuetify.breakpoint.mdAndUp) ? 220 : 140"
                    :loop="false"
                    :speed="1"
                    :autoPlay="false"
                    ref="logoAnimation"
                    @AnimControl="setLogoAnimationController"
                />
            </router-link>

            <v-spacer />

            <v-btn
                plain
                icon
                class="hidden-md-and-up"
                :to="{name:'Cart'}"
            >
                <v-badge color="green" :content="cartItemCount" :value="cartItemCount" overlap>
                    <v-icon>fa-shopping-cart</v-icon>
                </v-badge>
            </v-btn>

            <v-app-bar-nav-icon
                @click.stop="toggleDrawer"
            >
                <v-icon>fa-bars</v-icon>
            </v-app-bar-nav-icon>
        </v-app-bar>

        <navigation-drawer />
    </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import NavigationDrawer from "@/components/layout/NavigationDrawer";

export default {
    name: "AppBar",
    components: {
        LottieAnimation,
        NavigationDrawer
    },
    computed: {
        cartItemCount() {
            return this.$store.getters.cartItemCount;
        }
    },
    methods: {
        toggleDrawer() {
            this.$store.dispatch('toggleNavigationDrawer');
        },
        setLogoAnimationController(animation) {
            if (animation.isLoaded) {
                window.setTimeout(() => {
                    animation.play();
                }, 500);
            }
        }
    }
}
</script>

<style scoped>

</style>
