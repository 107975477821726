import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
import app from './modules/app'
import auth from './modules/auth'
import store from './modules/store'

let plugins = []
plugins.push(createPersistedState({
    key: process.env.VUE_APP_VUEX_STORAGE_NAME,
    paths: [
        'auth.accessToken',
        'auth.refreshToken',
        'store.expandedCatalogView',
        'store.cart'
    ]
}))

export default new Vuex.Store({
    modules: {
        app,
        auth,
        store,
    },
    strict: debug,
    plugins
})
