<template>
    <v-navigation-drawer
        fixed
        temporary
        app
        v-model="drawer"
        class="black"
        dark
        :width="($vuetify.breakpoint.smAndDown) ? '85vw' : 400"
    >
        <div class="d-flex flex-column fill-height">
            <p class="pa-5" v-if="user">
                <router-link :to="{name: 'Catalog' }" exact>
                    <v-img
                        :src="require('@/assets/images/logo.svg')"
                        width="100%"
                        max-width="120"
                        contain
                        class="mx-auto"
                    ></v-img>
                </router-link>
            </p>

            <v-list v-if="user">
                <v-list-item link>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                            Gebruiker
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-h6"><small>{{ user.user }}</small></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list nav>
                <template>
                    <div v-for="(item, index) in items" :key="'drawer-item-' + index">
                        <v-list-item :to="{ name: item.route, params: item.params }" :exact="true" link
                                     @click="closeDrawer">
                            <v-list-item-icon v-if="item.icon">
                                <v-icon>{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </template>
            </v-list>
            <p class="mt-auto text-center text--disabled">v{{appVersion}}</p>
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'NavigationDrawer',
    data: () => ({
        appVersion: process.env.VUE_APP_VERSION,
        items: [
            {title: 'Bestellen', route: 'Catalog', exact: true, icon: 'fal fa-store' },
            {title: 'Winkelwagen', route: 'Cart', exact: true, icon: 'fal fa-shopping-cart' },
            {title: 'Uitloggen', route: 'Logout', exact: true, icon: 'fal fa-arrow-right-from-bracket' }
        ]
    }),
    computed: {
        drawer: {
            get() {
                return this.$store.state.app.drawer
            },
            set(val) {
                this.$store.dispatch('toggleNavigationDrawer', val)
            }
        },
        ...mapState({
            user: state => state.auth.user
        })
    },
    methods: {
        closeDrawer() {
            this.$store.dispatch('toggleNavigationDrawer', false); // TODO: Dit misschien ergens globaal in de router
        }
    }
}
</script>
