<template>
    <div class="product d-flex flex-row flex-nowrap align-items-center"
         :class="{'is-expanded': expanded, 'is-compact': !expanded}"
    >
        <div v-if="false && expanded" class="me-2">
            <img src="//placehold.it/50x50" />
        </div>

        <div class="me-2">
            <v-text-field
                type="number"
                v-model="quantity"
                class="quantity-input"
                min="0"
                outlined
                dense
            />
        </div>

        <div class="d-flex flex-column flex-grow-1">
            <div>
                <strong>{{product.name}}</strong>
                <span class="text-muted" v-if="expanded && product?.attributes?.inhoud">{{product.attributes.inhoud}}</span>
            </div>
            <div>
                <code>{{product.sku}}</code>
            </div>
        </div>

<!--        <div v-if="expanded">-->
<!--            <i class="fal fa-cow"></i>-->
<!--        </div>-->

        <div v-if="expanded && displayPrices">
            {{product.price|currency}}
        </div>

    </div>
</template>

<script>
export default {
    name: "ProductBlock",
    props: {
        product: {
            type: Object,
            required: true
        },
        expanded: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        quantity: {
            get() {
                return this.cart
                    .filter(line => line.productId === this.product.id)
                    .reduce((carry, line) => {
                        return carry + line.quantity
                    }, 0)
            },
            set(val) {
                this.$store.dispatch('setCartQuantity', { product: this.product, quantity: val})
            }
        },
        cart() { return this.$store.state.store.cart },
        displayPrices() { return this.$store.state.store.displayPrices }
    }
}
</script>

<style scoped>

</style>
