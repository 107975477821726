<template>
    <div class="cart container">
        <div class="row">
            <div class="col">

                <h2>Bedankt voor je bestelling</h2>

                <p>Je bestelling is geplaatst.</p>

                <v-btn
                    :to="{name: 'Catalog'}"
                    color="accent"
                >
                    Nieuwe bestelling
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "OrderSuccess",
    methods: {
    }
}
</script>

<style scoped>

</style>
