// initial state
const state = {
    drawer: false,
    snackbar: {
        visible: false,
        text: '',
        color: 'success',
        timeout: 3000
    }
}

// getters
const getters = {

}

// actions
const actions = {
    showSnackbar({commit}, { text, timeout, color }) {
        if (!timeout) timeout = 3000;
        if (!color) color = 'success';
        commit('SHOW_SNACKBAR', { text, timeout, color })
    },
    toggleNavigationDrawer( { commit, state }, value) {
        if (typeof value !== 'undefined') {
            commit('SET_DRAWER_STATE', value);
        } else {
            commit('SET_DRAWER_STATE', !state.drawer);
        }

    }
}

// mutations
const mutations = {
    SHOW_SNACKBAR(state, { text, timeout, color }) {
        state.snackbar.visible = true;
        state.snackbar.text = text;
        state.snackbar.timeout = timeout;
        state.snackbar.color = color;
    },
    HIDE_SNACKBAR(state) {
        state.snackbar.visible = false;
    },
    SET_DRAWER_STATE(state, isOpen) {
        state.drawer = isOpen
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
