import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        options: {
            customProperties: true,
        },
        themes: {
            dark: {
                primary: '#000',
                secondary: '#666',
                accent: '#4FC1C1',
                error: '#E38875',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#E9CA41'
            },
        },
    },
    icons: {
        iconfont: 'fa',
        values: {
            sort: 'fal fa-long-arrow-up ml-1',
            prev: 'fal fa-chevron-left',
            next: 'fal fa-chevron-right',
            dropdown: 'fal fa-chevron-down',
            expand: 'fal fa-chevron-down',
        },
    },
    breakpoint: {
        thresholds: {
            xs: 540,
            sm: 800,
            md: 1280,
            lg: 1600
        },
    }
});
