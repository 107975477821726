import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false

import VueLuxon from "vue-luxon";
Vue.use(VueLuxon, {
    output: {
        locale: 'nl'
    }
});

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,
    {
        symbol: '€',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'front',
        symbolSpacing: true,
        avoidEmptyDecimals: '-',
    }
)

console.log('%cClevers %cIntern Bestelsysteem\r\n' +
    '%cVersie: %c' + process.env.VUE_APP_VERSION + '\r\n' +
    '%cAPI: %c' + process.env.VUE_APP_API_URL + '\r\n' +
    '%cOntwikkeld door: %cInternetbureau Forwart%c - %chttps://www.forwart.nl/',
    'color: #000000; font-weight: bold; font-size: 1.3em;',
    'color: #4FC1C1; font-size: 1.3em;',
    'color: #888',
    'color: #404041',
    'color: #888',
    'color: #60499e',
    'color: #888',
    'color: #ec2e24; font-weight: bold',
    'color: #888',
    'color: #00f; text-decoration: underline;'
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
