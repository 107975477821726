import apiClient from '@/api';
import Vue from 'vue'
import numeral from 'numeral';

// initial state
const state = {
    isLoading: true,

    products: [],
    categories: [],
    displayPrices: false,
    minOrderAmount: false,
    availableDeliveryDates: [],

    expandedCatalogView: true,
    cart: [],
}

// getters
const getters = {
    cartLinesWithProducts: state => {
        return state.cart.map(line => {
            return {
                ...line,
                product: state.products.find(p => p.id === line.productId)
            }
        }).filter(line => {
            return !!line.product;
        })
    },
    isCompleteCart: (state, getters) => {
        return (state.cart.length >= 1) && (getters.cartError === null);
    },
    cartTotal: (state, getters) => {
        if (!state.displayPrices) {
            return false;
        }
        let result = getters.cartLinesWithProducts.reduce((carry, line) => {
            let price = numeral(line.product.price).multiply(line.quantity);
            return carry.add(price.value());
        }, numeral(0))
        return result;
    },
    cartItemCount: (state) => {
        return state.cart.reduce((carry, line) => {
            return carry + line.quantity
        }, 0);
    },
    cartError: (state, getters) => {
        if (state.displayPrices) {
            const cartTotal = getters.cartTotal; // numeral
            const minOrderAmount = numeral(state.minOrderAmount);
            const diff = cartTotal.value() - minOrderAmount.value()
            if (diff < 0) {
                return 'Minimumbestelbedrag: € '+minOrderAmount.format('0,0.00')
            }
        }
        return null;
    }
}

// actions
const actions = {
    loadStoreConfig({ commit }) {
        commit('SET_STORE_LOADING', true);
        return apiClient.get(process.env.VUE_APP_API_URL + '/ios/config')
            .then(response => {
                commit('SET_STORE_CONFIG', response.data);
                commit('SET_STORE_LOADING', false);
            })
    },
    setCartQuantity({commit}, {product, quantity}) {
        if (quantity <= 0) {
            commit('REMOVE_FROM_CART', { productId: product.id})
        } else {
            commit('SET_CART_QUANTITY', { productId: product.id, quantity: parseInt(quantity)})
        }
    },
    submitOrder({state, dispatch}, { deliveryDate }) {
        return apiClient.post(process.env.VUE_APP_API_URL + '/ios/order', {
            cart: state.cart,
            deliveryDate
        })
            .then(() => {
                dispatch('showSnackbar', { text: 'Bestelling geplaatst '})
                return dispatch('clearCart')
            })
    },
    clearCart({ commit }) {
        commit('CLEAR_CART');
    }
}

// mutations
const mutations = {
    SET_STORE_LOADING(state, isLoading)
    {
        state.isLoading = isLoading;
    },
    SET_STORE_CONFIG(state, config) {
        state.products = config.products
        state.categories = config.categories
        state.displayPrices = config.displayPrices
        state.minOrderAmount = config.minOrderAmount
        state.availableDeliveryDates = config.availableDeliveryDates
    },
    TOGGLE_EXPANDED_CATALOG_VIEW(state, expandedCatalogView = null)
    {
        if (expandedCatalogView === null) {
            state.expandedCatalogView = !state.expandedCatalogView;
        } else {
            state.expandedCatalogView = expandedCatalogView;
        }
    },
    REMOVE_FROM_CART(state, { productId }) {
        state.cart = state.cart.filter(l => l.productId !== productId);
    },
    SET_CART_QUANTITY(state, { productId, quantity }) {
        const index = state.cart.findIndex(l => l.productId === productId);
        if (index !== -1) {
            Vue.set(state.cart[index], 'quantity', quantity)
        } else {
            state.cart.push({ quantity, productId })
        }
    },
    CLEAR_CART(state) {
        state.cart = [];
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
