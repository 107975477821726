import apiClient from '@/api';

// initial state
const state = {
    user: null,
    accessToken: null,
}

// getters
const getters = {
    isLoggedIn: state => {
        return !!state.accessToken
    },
}

// actions
const actions = {
    login({commit, dispatch}, { username, password }) {
        return apiClient.post('/ios_login_check', { username, password }, { addBearerToken: false })
            .then(response => {
                if (response.status === 200) {
                    commit('SET_AUTH_TOKENS', {accessToken: response.data.token, refreshToken: response.data.refresh_token })
                    return dispatch('getUserInfo')
                        .then(() => {
                            return dispatch('loadStoreConfig').then(() => {
                                return true;
                            })
                        })
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    getUserInfo({commit}) {
        return apiClient.get('/ios/me')
            .then(response => {
                if (response.status === 200) {
                    commit('SET_USER', response.data);
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    logout({commit}) {
        commit('CLEAR_AUTH_STATE');
    },
    async refreshToken({state, commit }) {
        let formData = new FormData();
        formData.set('refresh_token', state.refreshToken);
        return apiClient.post(
            '/ios_token_refresh',
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                addBearerToken: false
            }
        ).then(response => {
            commit('SET_AUTH_TOKENS', {accessToken: response.data.token, refreshToken: response.data.refresh_token })
        })
    },

}

// mutations
const mutations = {
    SET_AUTH_TOKENS(state, { accessToken, refreshToken }) {
        state.accessToken = accessToken
        state.refreshToken = refreshToken
    },
    SET_USER(state, data) {
        state.user = data;
    },
    CLEAR_AUTH_STATE(state) {
        state.user = null;
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}
