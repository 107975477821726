<template>
    <div class="container">

        <div class="row">
            <div class="col-md-8 col-xl-9">
                <!-- Header row -->
                <div class="row">
                    <div class="col" v-if="!isMobile && false"><!-- disabled omdat we nog niet alle informatie hebben -->
                        <v-switch
                            v-model="expandedCatalogView"
                            label="Uitgebreide weergave"
                            color="accent"
                        />
                    </div>
                    <div class="col">
                        <v-text-field
                            v-model.trim="searchQuery"
                            placeholder="Zoeken..."
                            filled
                            clearable
                            dense
                            xx-lellik-prepend-inner-icon="fa-magnifying-glass"
                        ></v-text-field>
                    </div>
                </div>

                <v-tabs v-model="selectedCategory" v-if="isTabBarVisible" color="accent" class="mb-3">
                    <v-tab
                        v-for="category in categories"
                        :key="category.id"
                        :disabled="isSearchQueryActive"
                    >
                        {{ category.name }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="selectedCategory" v-if="!isLoading">

                    <v-tab-item
                        v-for="category in categories"
                        :key="category.id">
                        <v-card flat color="#fefefe" light>
                            <v-card-title v-if="isCategoryNameVisible">{{ category.name }}</v-card-title>
                            <v-card-text>

                                <!-- TODO Frontend: Responsive maken -->
                                <div class="row">
                                    <div
                                        v-for="product in getCategoryProducts(category)"
                                        :key="product.id"
                                        class="col-xs-12 col-md-4 col-xl-3">
                                        <ProductBlock
                                            :product="product"
                                            :expanded="expandedCatalogView"
                                        ></ProductBlock>
                                    </div>
                                </div>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>

                <div v-if="isLoading">
                    <v-sheet class="pa-5">
                        <div class="mx-auto" style="width: 32px;">
                            <v-progress-circular indeterminate></v-progress-circular>
                        </div>
                    </v-sheet>
                </div>

                <div v-if="products.length === 0 && !isLoading">
                    <v-alert color="warning">
                        Er zijn geen beschikbare producten gevonden voor uw account.
                    </v-alert>
                </div>
            </div>
            <div class="col-md-4 col-xl-3 hidden-sm-and-down" v-if="!isLoading">
                <div class="w-100">
                    <h2 class="mb-2">Jouw bestelling</h2>
                    <CartOverview></CartOverview>
                    <v-btn
                        block
                           :disabled="!isCompleteCart"
                           :to="{name: 'Cart'}"
                           color="accent"
                           rounded
                           class="elevation-0 mt-2"
                    >Plaats bestelling
                    </v-btn>

                </div>
            </div>
        </div>


    </div>
</template>

<script>
// @ is an alias to /src

import ProductBlock from "../components/store/ProductBlock";
import CartOverview from "../components/store/CartOverview";

export default {
    name: 'Catalog',
    components: {CartOverview, ProductBlock},
    data: () => ({
        selectedCategory: 0, // standaard eerste tab selecteren
        searchQuery: '',
    }),
    computed: {
        isLoading() {
            return this.$store.state.store.isLoading
        },
        isMobile() {
            return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
        },
        isTabBarVisible() {
            return this.isMobile || this.expandedCatalogView
        },
        isCategoryNameVisible() {
            return !this.isTabBarVisible && !this.isSearchQueryActive
        },
        isSearchQueryActive () {
           return this.searchQuery.length >= 1;
        },
        expandedCatalogView: {
            get() {
                return this.isMobile || this.$store.state.store.expandedCatalogView
            },
            set(val) {
                this.$store.commit('TOGGLE_EXPANDED_CATALOG_VIEW', val);
            }
        },
        categories() {
            return this.$store.state.store.categories
        },
        products() {
            return this.$store.state.store.products;
        },
        filteredProducts() {
            const searchQuery = this.searchQuery.toLowerCase();
            const products = this.products
                .filter(product => {
                    return product.stock === null || product.stock >= 1;
                })

            if (!searchQuery) {
                return products;
            }
            return products.filter(product => {
                return (product.name.toLowerCase().includes(searchQuery) || product.sku.toLowerCase().includes(searchQuery))
            });
        },
        isCompleteCart() {
            return this.$store.getters.isCompleteCart
        },
    },
    methods: {
        getCategoryProducts(category) {
            return this.filteredProducts.filter(
                p => p.categoryId === category.id
            );
        }
    }
}
</script>
