<template>
    <v-container class="fill-height login-container px-6">
        <v-form ref="form" @submit.prevent="checkLogin">
            <lottie-animation
                class="mb-4"
                path="animations/clevers-logo-v2.json"
                :width="250"
                :loop="false"
                :speed="1"
                :autoPlay="false"
                ref="logoAnimation"
                @AnimControl="setLogoAnimationController"
            />

            <v-card class="elevation-0">
                <v-card-text>
                    <v-text-field
                        type="email"
                        outlined
                        hide-details="auto"
                        single-line
                        v-model="username"
                        label="Gebruikersnaam"
                        :rules="usernameRules"
                        required
                        class="mb-4 mt-1"
                    ></v-text-field>
                    <v-text-field
                        type="password"
                        outlined
                        hide-details="auto"
                        single-line
                        v-model="password"
                        label="Wachtwoord"
                        :rules="passwordRules"
                        required
                        class="mb-5"
                    ></v-text-field>
                    <v-btn
                        type="submit"
                        color="accent"
                        large
                        rounded
                        block
                        :loading="isLoading"
                        :disabled="isLoading || username.length < 1 || password.length < 1"
                        class="mb-2 elevation-0"
                    >
                        Inloggen
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-form>

    </v-container>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: 'Login',
    components: {
        LottieAnimation
    },
    data: () => ({
        isLoading: false,
        username: '',
        usernameRules: [
            v => !!v || 'E-mailadres is verplicht'
        ],
        password: '',
        passwordRules: [
            v => !!v || 'Wachtwoord is verplicht'
        ],
    }),
    methods: {
        checkLogin() {
            // Check if form is valid
            if (!this.$refs.form.validate()) {
                return;
            }

            this.isLoading = true;

            // Submit data to auth store
            this.$store.dispatch('login', {
                username: this.username,
                password: this.password,
            }).then((success) => {
                if (success) {
                    this.$router.push({name: "Catalog"})
                } else {
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige inloggegevens'});
                }
            }).catch((e) => {
                console.error(e);
                return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige inloggegevens'});
            }).finally(() => {
                this.isLoading = false;
            });
        },
        setLogoAnimationController(animation) {
            if (animation.isLoaded) {
                window.setTimeout(() => {
                    animation.play();
                }, 500);
            }
        }
    }
}
</script>
