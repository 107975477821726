<template>
    <div v-if="!isLoading">
        <div v-if="products.length > 0">
            <table>
                <tr v-for="line in products" :key="line.productId">
                    <td class="text-right">{{line.quantity}}x</td>
                    <td><code class="text-no-wrap">{{line.product.sku}}</code></td>
                    <td>{{line.product.name}}</td>
                    <td v-if="displayPrices" class="text-no-wrap text-right">
                        {{line.product.price|currency}}
                    </td>
                </tr>
                <tr v-if="displayPrices">
                    <td colspan="3" class="font-weight-bold text-right">
                        Totaal:
                    </td>
                    <td class="text-no-wrap text-right font-weight-bold">
                        {{cartTotal.format('0.0')|currency}}
                    </td>
                </tr>
            </table>


            <p class="red--text" v-if="cartError">{{cartError}}</p>

            <v-btn
                @click="clearCart"
                rounded
                small
                block
                color="warning"
                class="mt-2 mb-2"
            >Winkelwagen opschonen</v-btn>
        </div>
        <div v-else>
            Je winkelwagen is nog leeg.
        </div>
    </div>
</template>

<script>
export default {
    name: "CartOverview",
    computed: {
        products () {
            return this.$store.getters.cartLinesWithProducts;
        },
        cartTotal() {
            return this.$store.getters.cartTotal
        },
        cart() { return this.$store.state.store.cart },
        cartError() { return this.$store.getters.cartError },
        isLoading() { return this.$store.state.store.isLoading },
        displayPrices() { return this.$store.state.store.displayPrices }
    },
    methods: {
        clearCart() {
            this.$store.dispatch('clearCart')
        }
    }
}
</script>

<style scoped>

</style>
