<template>
    <v-app :class="{ 'is-login': !isLoggedIn }">
        <AppBar v-if="isLoggedIn"></AppBar>

        <v-main>
            <router-view/>
        </v-main>

        <Snackbar></Snackbar>
    </v-app>
</template>

<script>

import AppBar from "./components/layout/AppBar";
import Snackbar from "./components/layout/Snackbar";

export default {
    name: 'App',
    components: {Snackbar, AppBar},
    mounted() {
        if (this.$store.state.auth.accessToken) {
            this.$store.dispatch('getUserInfo');
            this.$store.dispatch('loadStoreConfig');
        }
    },
    data: () => ({
        //
    }),
    computed: {
        isLoggedIn () {
            return this.$store.getters.isLoggedIn
        }
    }
};
</script>

<style lang="scss">
@import "assets/scss/app"
</style>
