import axios from 'axios'
import router from '../router'
import store from '../store'

let client = axios.create({
    baseURL: process.env.VUE_APP_API_URL.trim('/'),
    responseType: 'json',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
});

client.interceptors.request.use(async (config) => {
    let accessToken = store.state.auth.accessToken
    if (accessToken && config.addBearerToken !== false) {
        config.headers.Authorization = 'Bearer ' + accessToken
    }
    return config
})

client.interceptors.response.use(response => response, error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
        console.log('%capiClient error:%c Response status 401 (Unauthorized)', 'color: #ec2e24; font-weight: bold', 'color: #ec2e24');

        if (!originalRequest._retry && originalRequest.url.indexOf('/token/refresh') === -1) {
            console.log('%capiClient error:%c Retrying token refresh', 'color: #ec2e24; font-weight: bold', 'color: #ec2e24');
            originalRequest._retry = true;
            return store.dispatch('refreshToken')
                .then(() => {
                    console.log('%capiClient error:%c Received new access token', 'color: #28a745; font-weight: bold', 'color: #28a745');
                    originalRequest.headers['Authorization'] = 'Bearer ' + store.state.auth.accessToken;
                    //retry failed request
                    return client.request(originalRequest)
                }).catch(err => {
                    console.log('%capiClient error:%c Access token refresh failed', 'color: #28a745; font-weight: bold', 'color: #28a745');
                    console.error(err)
                    store.dispatch('logout')
                    if (router.currentRoute.name !== 'Login') {
                        router.push({'name': 'Login'})
                    }
                })
        } else {
            console.log('%capiClient error:%c Token refresh failed', 'color: #ec2e24; font-weight: bold', 'color: #ec2e24');
            console.log(originalRequest._retry, originalRequest.url.indexOf('/token/refresh'));
            // Redirect to login
            store.dispatch('logout')
            if (router.currentRoute.name !== 'Login') {
                router.push({'name': 'Login'})
            }
        }
    }

    // retrow the error
    return Promise.reject(error)
})



client.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
        // Redirect to login
        store.dispatch('logout')
        if (router.currentRoute.name !== 'Login') {
            router.push({'name': 'Login'})
        }
    }

    // rethrow the error
    return Promise.reject(error)
})

export default client;
