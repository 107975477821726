import Vue from 'vue'
import VueRouter from 'vue-router'
import Catalog from '../views/Catalog.vue'
import Login from '../views/Login.vue'
import Cart from '../views/Cart.vue'
import store from '../store'
import OrderSuccess from "../views/OrderSuccess.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Catalog',
        component: Catalog,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/order-success',
        name: 'OrderSuccess',
        component: OrderSuccess,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter(to, from, next) {
            store.dispatch('logout')
                .then(() => {
                    next({
                        name: 'Login'
                    })
                })
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isLoggedIn) {
            next({
                name: 'Login'
            })
        }
    }

    // Default case. The user is send to the desired route.
    next()
})


export default router
